import { Button } from '@mui/material';
import Box from '@mui/material/Box';
import * as React from 'react';
import useI18n from '../../../hooks/useI18n';
import DatePickerRange from "../../custom/DatePickerRange/DatePickerRange";
import moment from 'moment';
import { useParams } from 'react-router-dom';
import probeService from '../../../api/probe';
import deviceService from '../../../api/device';
import PDFViewer from '../../custom/PDFViewer/PDFViewer';
import CircularProgress from '@mui/material/CircularProgress';


export default function Report() {
    const { language, l } = useI18n();
    const { id } = useParams();
    const [from, setStartDate] = React.useState('');
    const [to, setEndDate] = React.useState('');
    const [pdfBlob, setPdfBlob] = React.useState(null);
    const [loading, setLoading] = React.useState(false);

    React.useEffect(() => {
        const startDate = moment().subtract(7, 'days').startOf('day');//.format('YYYY-MM-DD HH:mm Z');
        const endDate = moment().endOf('day');//.format('YYYY-MM-DD HH:mm Z');
        setStartDate(startDate);
        setEndDate(endDate);
    }, []);

    const getGenerateReportByDeviceId = async ({ id, from, to, lang }) => {
        try {
            const generatedContent = await probeService.getGenerateReportByDeviceIdAsync({ id, from, to, lang });
            return generatedContent;
        } catch (error) {
            console.error('Error fetching getGenerateReportByDeviceId: ', error);
        }
    }

    const handleGenerateClick = async () => {
        let startDate = from.format('YYYY-MM-DD HH:mm ZZ');
        let endDate = to.format('YYYY-MM-DD HH:mm ZZ');

        try {
            const reportBuffer = await getGenerateReportByDeviceId({ id, from: startDate, to: endDate, lang: language });
            const bufferData = reportBuffer.buf.data;
            const uint8Array = new Uint8Array(bufferData);
            const blob = new Blob([uint8Array], { type: 'application/pdf' });

            setPdfBlob(blob);
        } catch (error) {
            console.error('Error generating report:', error);
        } finally {
            setLoading(false); 
        }
    }

    const handleExportClick = async () => {
        const device = await deviceService.getFindDeviceById(id, language);
        const url = URL.createObjectURL(pdfBlob);
        const a = document.createElement('a');
        a.href = url;
        const sratDate = moment(from).format('DD-MM-YYYY');
        const endDate = moment(to).format('DD-MM-YYYY');
        let period = sratDate + ' - ' + endDate;
        if (sratDate === endDate) {
            period = sratDate;
        }
        a.download = l('Report') + ' ' + device.comment + ' ' + period + '.pdf';
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
    }
    
    const handleStartDateChange = (date) => {
        let startDate = date.startOf('day');
        setStartDate(startDate);
    };

    const handleEndDateChange = (date) => {
        let endDate = date.endOf('day');
        setEndDate(endDate);
    };

    return (
        <Box overflow={'hidden'}>
            <Box display={'flex'} flexDirection={'start'} sx={{ gap: 1, padding: 3 }} variant="outlined">
                <DatePickerRange
                    lableStart={l('Start time')}
                    lableEnd={l('End time')}
                    sx={{ maxWidth: '500px' }}
                    onChangeStartDate={handleStartDateChange}
                    onChangeEndDate={handleEndDateChange}
                    defaultStartDate={moment().subtract(7, 'days').format('YYYY-MM-DD HH:mm Z')}
                    defaultEndDate={moment().endOf('day').format('YYYY-MM-DD HH:mm Z')}
                    type='DatePicker'
                    
                />
                {loading && <CircularProgress />}
                {pdfBlob && <Button size="small" variant="contained" onClick={handleExportClick}>{l('Download')}</Button>}
                <Button size="small" style={{ marginLeft: 'auto' }} variant="contained" onClick={handleGenerateClick}>{l('Generate')}</Button>
            </Box>
                {pdfBlob && <PDFViewer pdfBlob={pdfBlob} />}
        </Box>
    );
};