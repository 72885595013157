const {REACT_APP_SERVER_URL, REACT_APP_REPORT_SERVER_URL} = process.env;
const USER_URL = REACT_APP_SERVER_URL + "/user";
const ROLE_URL = REACT_APP_SERVER_URL + "/role";
const PROBE_URL = REACT_APP_SERVER_URL + "/probe";
const PROBE_REPORT_URL = REACT_APP_REPORT_SERVER_URL + "/probe";
const DEVICE_URL = REACT_APP_SERVER_URL + "/device";
const DATA_TYPE_URL = REACT_APP_SERVER_URL + "/dataType";
const COMPANY_URL = REACT_APP_SERVER_URL + "/company";
const PUBLIC_URL = REACT_APP_SERVER_URL + "/public";
const ACCESS_TOKEN_URL = REACT_APP_SERVER_URL + "/accessToken";
const SMS_SETTING_URL = REACT_APP_SERVER_URL + "/smsSetting";
const ALARM_URL = REACT_APP_REPORT_SERVER_URL + "/alarm";

export const PUBLIC_URLS = {
    LOGIN: PUBLIC_URL + '/login',
    FORGOT_PASSWORD: PUBLIC_URL + '/forgotPassword',
    AUTHENTICATION_EMAIL: PUBLIC_URL + '/authenticationEmail'
};

export const USERS_URLS = {
    FIND_BY_ID: USER_URL + "/",
    FIND_ALL: USER_URL + "/list",
    FIND_ALL_FOR_COMPANY: USER_URL + '/list/',
    CREATE: USER_URL + '/create',
    UPDATE: USER_URL + '/update/',
    DELETE: USER_URL + '/delete/',
    CHANGE_PASSWORD: USER_URL + '/changePassword',
    UPDATE_ALARM_TIMESTAMP: USER_URL + '/updateAlarmTimeStamp/'
};

export const ROLE_URLS = {
    FIND_BY_ROLE: ROLE_URL + "/",
    FIND_ALL: ROLE_URL + "/list",
    FIND_ALLOWED_ROLES: ROLE_URL + "/listAllowedRoles",
    CREATE: ROLE_URL + '/create',
    UPDATE: ROLE_URL + '/update/',
    DELETE: ROLE_URL + '/delete/'
};

export const PROBE_URLS = {
    FIND_PROBE_BY_ID: PROBE_URL + '/',
    FIND_BY_DEVICE_EXTENDED: PROBE_REPORT_URL + "/getReportByDeviceId/",
    FIND_BY_PROBE_EXTENDED: PROBE_REPORT_URL + "/getReportByProbeId/",
    FIND_BY_PROBE_IDS :PROBE_REPORT_URL + "/getReportByProbesIdArray/",
    FIND_BY_DEVICE_GENERATE: PROBE_REPORT_URL + "/getGenerateReportByDeviceId/",
    FIND_BY_DEVICE: PROBE_REPORT_URL + "/list/",
    FAST_FIND_BY_DEVICE: PROBE_REPORT_URL + "/fastlist/",
    FIND_PROBE_TYPES: PROBE_URL + "/listProbeTypes",
    CREATE: PROBE_URL + '/create',
    UPDATE: PROBE_URL + '/update/',
    EXPORT_BY_PROBE_ID: PROBE_URL + '/export/',
    // DELETE: PROBE_URL + '/delete'
};

export const DEVICE_URLS = {
    FIND_BY_ID: DEVICE_URL + "/",
    LIST_BY_ID_EXTENDED: DEVICE_URL + "/listByIdExtended/",
    LIST_BY_COMPANY_ID: DEVICE_URL + "/listByCompanyId/",
    CREATE: DEVICE_URL + '/create',
    UPDATE: DEVICE_URL + '/update/',
    DELETE: DEVICE_URL + '/delete/',
    GET_IMAGE_BY_DEVICE_ID: DEVICE_URL + '/image/',
};

export const DATA_TYPE_URLS = {
    LIST_BY_PROBE_ID: DATA_TYPE_URL + "/listByProbeId/",
    LIST_BY_REC_TIME: DATA_TYPE_URL + '/listByRecTime/'
};

export const COMPANY_URL_URLS = {
    FIND_ALL: COMPANY_URL + "/list",
    FIND_BY_ID_EXTENDED: COMPANY_URL + '/',
    FIND_ALLOWED_COMPANIES: COMPANY_URL + '/listAllowedCompanies',
    CREATE: COMPANY_URL + '/create',
    UPDATE: COMPANY_URL + '/update/',
    DELETE: COMPANY_URL + '/delete/',
    GET_IMAGE_BY_DEVICE_ID: COMPANY_URL + '/image/'
};

export const ACCESS_TOKEN_URLS = {
    LIST: ACCESS_TOKEN_URL + "/list",
    CREATE: ACCESS_TOKEN_URL + '/create',
    DELETE: ACCESS_TOKEN_URL + '/delete/'
};

export const SMS_SETTING_URLS = {
    LIST: SMS_SETTING_URL + "/list",
    UPDATE: SMS_SETTING_URL + '/update'
};

export const ALARM_URLS = {
    FIND_BY_COMPANY_ID: ALARM_URL + "/getReportAlarmsByCompanyID/",
    FIND_BY_LAST_TIMESTAMP: ALARM_URL + "/getAlarmsByTimeStamp",
}

