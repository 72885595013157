import { getData, postData, getImage } from "../lib/fetchApi";
import { DEVICE_URLS } from "../constants/urls";
import { defaultLocale } from "../config";

const deviceService = {
    listByIdExtendedAsync: (data, lang = defaultLocale) => {
        return getData({ url: DEVICE_URLS.LIST_BY_ID_EXTENDED + data, lang })
    },
    listByCompanyAsync: (data, lang = defaultLocale) => {
        return getData({ url: DEVICE_URLS.LIST_BY_COMPANY_ID + data, lang })
    },
    createDeviceAsync: (data, lang = defaultLocale) => {
        return postData({ url: DEVICE_URLS.CREATE, lang, data })
    },
    updateDeviceAsync: ({ id, data, lang = defaultLocale, file, type }) => {
        return postData({ url: DEVICE_URLS.UPDATE + id, lang, data, file, type })
    },
    deleteDeviceAsync: ({ id, data, lang = defaultLocale }) => {
        return postData({ url: DEVICE_URLS.DELETE + id, data, lang })
    },
    getImageByDeviceId: (data, lang = defaultLocale) => {
        return getImage({ url: DEVICE_URLS.GET_IMAGE_BY_DEVICE_ID + data, lang })
    },
    getFindDeviceById: (id, lang = defaultLocale) => {
        return getData({ url: DEVICE_URLS.FIND_BY_ID + id, lang })
    }
}
export default deviceService;