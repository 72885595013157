import * as React from 'react';
import useI18n from '../../../hooks/useI18n';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { Modal, TextField, FormControlLabel, Checkbox, Select, MenuItem, FormControl, InputLabel } from '@mui/material';
import BasicModal from '../../custom/Modal/BasicModal' 
import userService from '../../../api/user';
import roleService from '../../../api/role';
import PhoneInput from '../../custom/PhoneInput/PhoneInput';
import { useRecoilState } from "recoil";
import { currentCompanyState } from '../../../state/companies';

export default function AddUser ({users, onSubmitSuccess}) {
    const { l, language } = useI18n();
    const [open, setOpen] = React.useState(false);
    const handleClose = () => setOpen(false);
    const [user, setUser] = React.useState([]);
    const [roles, setRoles] = React.useState([]);
    const [modalOpen, setModalOpen] = React.useState(false);
    const [roleValue, setRoleValue] = React.useState(null);
    const [email, setEmail] = React.useState('email@com.ua');
    const [title, setTitle] = React.useState('');
    const [text, setText] = React.useState('');
    const [selectedCompany, setSelectedCompany] = useRecoilState(currentCompanyState);

    const handleOpen = () => {
      const getDataRoles = async () => {
          try {
              const result = await roleService.getAllowedRolesAsync(language); 
              setRoles(result.map(role => {return { user_role: role.user_role, comment: role.comment };}));
          } catch (error) {
              console.error('Error fetching roles:', error);
          }
      };
      getDataRoles();

      setOpen(true);
    };

    const handleSubmit = async(event) => {
      event.preventDefault();
      if (validateEmail(email)) {
        try {
          user.company_id = selectedCompany?.id;
          await userService.createUserAsync(user, language);
          onSubmitSuccess();
        } catch (error) {
            console.error('Error fetching users:', error);
        }
        setOpen(false);
      } else {
        setModalOpen(true);
        setTitle(l('Check data'));
        setText(l('Please check your data. Some fields are empty or invalid.'));
      }
    }

    const emailExists = () => {
        setModalOpen(true);
        setTitle(l('User exists'));
        setText(l('User is alresdy exists'));
    }

    const handleInputBlur = (event) => {
      const { name, value } = event.target;
      const email = users?.filter(user => user?.email === (event.target.value).trim());
      if (email.length) {
        event.target.value = "";
        event.target.focus();
        emailExists();
      }
      setUser({...user, [name]: value});
      if (name === 'email') setEmail(value);
      if (name === 'user_role') setRoleValue(value);
    }

    function handleCancel () {
      setOpen(false); 
    }

    function handleInputChange (event) {
      const { name, value, checked } = event.target;
      if (name === 'is_active' && checked === true) {
        setUser({
          ...user,
          [name]: true
        })
      } else if (name === 'is_active' && checked === false) {
        setUser({
          ...user,
          [name]: false
        })
      } else {
        setUser({
        ...user,
        [name]: value
      })
    }}

    const validateEmail = (email) => {
      const regex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
      return regex.test(email);
    }

    return (
        <>
        <Button size="small" variant="contained" onClick={handleOpen}>{l('Add user')}</Button>
        {modalOpen ? 
          <BasicModal isOpened={true}
                      type="warning"
                      title={title}
                      text={text}
          /> : ""
        }
        <Modal
          open={open}
          onClose={handleClose}
        >
          <Box 
            component="form"
            sx={{ '& .MuiTextField-root': { m: 1, width: '100%', minWidth: "300px" } }}
            noValidate
            autoComplete="off"
            onSubmit={handleSubmit}
            className="modal">
            <Typography id="modal-modal-title" variant="h6" color='#1976d2' component="h2" sx={{fontSize: 24}}>
              {l('Add user')}
            </Typography>
            <Box display="flex"
                 flexDirection='column'
                 alignItems="center"
                 justifyContent="space-between"
                 flexWrap="wrap"
                 mb={2}>
              <Box display='flex' flexDirection='row' sx={{ flexWrap: 'wrap' }}>
                {validateEmail(email) ? 
                  <TextField name="email"
                             required
                             id="email"
                             label={l("User Email")}
                             onBlur={handleInputBlur}
                             variant="outlined"
                             size="small"/> : 
                  <TextField name="email"
                             required
                             error
                             id="email"
                             label={l("User Email")}
                             onBlur={handleInputBlur}
                             variant="outlined"
                             helperText={l("Email is invalid")}
                             size="small"
                             autoComplete='off'
                             />
                }
                <TextField name="user_name"
                           required
                           error={user.user_name === ''}
                           id="user_name"
                           label={l("User Name")}
                           onBlur={handleInputBlur}
                           variant="outlined"
                           size="small"
                           autoComplete='off'
                           />
                <TextField name="password"                         
                           required
                           error={user.password === ''}
                           type="password"
                           id="password"
                           label={l("User Password")}
                           onBlur={handleInputBlur}
                           variant="outlined"
                           autoComplete='new-password'
                           size="small" />
                <Box width={'100%'} display='flex' flexDirection='column'>
                <PhoneInput name="phone" value={user.phone} sx={{ width: 'calc(100% - 15px)', marginTop: -1, marginLeft: 1, marginBottom: 2 }} isLableOnBorder={true} handleChange={handleInputBlur} />
              </Box>
              <Box paddingLeft={1} paddingRight={1} gap={2} display='flex' width={'100%'} flexDirection='column' sx={{ flexWrap: 'wrap' }}>
                <FormControl sx={{ width: '100%'  }} size="small">
                  <InputLabel id="demo-select-small-label">{l('Role')}</InputLabel>
                  <Select
                    labelId="roles-select-label"
                    id="roles-select"
                    name="user_role"
                    value={roleValue || ''}
                    label={l("Role")}
                    onChange={handleInputBlur}
                  >
                    {roles ? roles?.map( (role) => (
                      <MenuItem key={role.user_role} value={role.user_role}>{role.comment}</MenuItem> )) : ""
                    }
                  </Select>
                </FormControl>
              </Box>
              </Box>
              
              <span style={{color: '#ccc', alignSelf: 'flex-start', fontSize: 'small'}}> * - {l('field is required')}</span>
              <FormControlLabel control={
                  <Checkbox id="is_active"
                          name="is_active"
                          defaultChecked
                          onChange={handleInputChange}
                          size="small" />
              } label={l("User is active")} />
            </Box>
            <Box>
              <Button sx={{mr: 3, mb: 2}} type="submit" size="small" variant="contained" color='success'>{l('Save')}</Button>
              <Button sx={{mb: 2}} onClick={handleCancel} size="small" variant="contained" color='error'>{l('Cancel')}</Button>
            </Box>
          </Box>
        </Modal>
      </>
      );
}