/* eslint-disable no-useless-computed-key */
const enDic = {
    ["Login"]: "Логин",
    ["login"]: "Логин",
    ["Password"]: "Пароль",
    ["Users"]: 'Пользователи',
    ["User"]: 'Пользователь',
    ["Companies"]: "Компании",
    ["Devices"]: "Устройства",
    ["Roles"]: "Роли",
    ["Messages"]: "Сообщения",
    ["Graphs"]: "Графики",
    ["Graphics"]:"Графики",
    ["Email"]: "Электронная почта",
    ["Phone"]: "Телефон",
    ["Name"]: "Имя",
    ["Role"]: "Роль",
    ["Company Name"]: "Название компании",
    ["Company Description"]: "Описание компании",
    ["Save"]: "Сохранить",
    ["User is active"]: "Пользователь активен",
    ["Company is active"]: "Компания активна",
    ["Loading"]: "Загрузка",
    ["Page not found"]: "Страница не найдена",
    ["Sign in"]: "Войти",
    ["Sign In"]: "Войти",
    ["Probes"]: "Показатели",
    ["Setting"]: "Настройка",
    ["Settings"]: "Настройки",
    ["Notification"]: "Уведомление",
    ["Title"]: "Заголовок",
    ["Logout"]: "Выйти",
    ["ID"]: "ID",
    ["Company ID"]: "ID компании",
    ["Is Active"]: "Активен",
    ["User Role"]: "Роль пользователя",
    ["Created At"]: "Создано",
    ["Comment"]: "Комментарий",
    ["Allow Create Companies"]: "Разрешить Создание Компаний",
    ["Allow Create Devices"]: "Разрешить Создание Устройств",
    ["Allow Create Roles"]: "Разрешить Создание Ролей",
    ["Allow Create Users"]: "Разрешить Создание Пользователей",
    ["Allow Delete Companies"]: "Разрешить Удаление Компаний",
    ["Allow Delete Devices"]: "Разрешить Удаление Устройств",
    ["Allow Delete Roles"]: "Разрешить Удаление Ролей",
    ["Allow Delete Users"]: "Разрешить Удаление Пользователей",
    ["Allow Edit Companies"]: "Разрешить Редактирование Компаний",
    ["Allow Edit Devices"]: "Разрешить Редактирование Устройств",
    ["Allow Edit Roles"]: "Разрешить Редактирование Ролей",
    ["Allow Edit Users"]: "Разрешить Редактирование Пользователей",
    ["Parameter Name"]: "Имя Параметра",
    ["Status"]: "Статус",
    ["Date/Time"]: "Дата/Время",
    ["System Name"]: "Название системы",
    ["Time/Date data updating"]: "Время/Дата обновления данных",
    ["Date/Time data updating"]: "Дата/Время обновления данных",
    ["Latest Value"]: "Последнее Значение",
    ["Low Critical Limit"]: "Нижний Критический Предел",
    ["Low Limit"]: "Нижний Предел",
    ["High Limit"]: "Верхний Предел",
    ["High Critical Limit"]: "Верхний Критический Предел",
    ["Date"]: "Дата",
    ["Active"]: "Активен",
    ["Connected"]: "Подключен",
    ["IP"]: "IP",
    ["Port"]: "Порт",
    ["Start time"]: "Время Начала",
    ["End time"]: "Время Окончания",
    ["Rows per page"]: "Строк на странице",
    ["of"]: "из",
    ["Add user"]: "Добавить пользователя",
    ["User Email"]: "Email Пользователя",
    ["Email is invalid"]: "Email недействителен",
    ["User Name"]: "Имя Пользователя",
    ["User Password"]: "Пароль Пользователя",
    ["User Phone"]: "Телефон Пользователя",
    ["field is required"]: "поле обязательно для заполнения",
    ["Cancel"]: "Отмена",
    ["Role: "]: "Роль: ",
    ["Allow create companies"]: "Разрешить создавать компании",
    ["Allow create devices"]: "Разрешить создавать устройства",
    ["Allow create roles"]: "Разрешить создавать роли",
    ["Allow create users"]: "Разрешить создавать пользователей",
    ["Allow create access tokens"]: "Разрешить создавать токены доступа",
    ["Allow edit companies"]: "Разрешить редактировать компании",
    ["Allow edit devices"]: "Разрешить редактировать устройства",
    ["Allow edit roles"]: "Разрешить редактировать роли",
    ["Allow edit users"]: "Разрешить редактировать пользователей",
    ["Allow delete companies"]: "Разрешить удалять компании",
    ["Allow delete devices"]: "Разрешить удалять устройства",
    ["Allow delete roles"]: "Разрешить удалять роли",
    ["Allow delete users"]: "Разрешить удалять пользователей",
    ["Allow delete access tokens"]: "Разрешить удалять токены доступа",
    ["Save changes"]: "Сохранить изменения",
    ["Delete role"]: "Удалить роль",
    ["Allow to create companies"]: "Разрешить создавать компании",
    ["Allow to create devices"]: "Разрешить создавать устройства",
    ["Allow to create roles"]: "Разрешить создавать роли",
    ["Allow to create users"]: "Разрешить создавать пользователей",
    ["Allow to create access tokens"]: "Разрешить создавать токены доступа",
    ["Allow to edit companies"]: "Разрешить редактировать компании",
    ["Allow to edit devices"]: "Разрешить редактировать устройства",
    ["Allow to edit roles"]: "Разрешить редактировать роли",
    ["Allow to edit users"]: "Разрешить редактировать пользователей",
    ["Allow to delete companies"]: "Разрешить удалять компании",
    ["Allow to delete devices"]: "Разрешить удалять устройства",
    ["Allow to delete roles"]: "Разрешить удалять роли",
    ["Allow to delete users"]: "Разрешить удалять пользователей",
    ["Allow to delete access tokens"]: "Разрешить удалять токены доступа",
    ["Average Value"]: "Среднее значение",
    ["Min. Value"]: "Мин. значение",
    ["Max. Value"]: "Макс. значение",
    ["Hight Limit"]: "Верхний предел",
    ["Hight Critical Limit"]: "Критический верхний предел",
    ["Add probe"]: "Добавить Показатель",
    ["Probe Name"]: "Название Показателя",
    ["Add role"]: "Добавить роль",
    ["Forgot Password"]: "Забыли пароль",
    ["Email is required"]: "Email обязателен",
    ["Phone is required"]: "Телефон обязателен",
    ["Submit"]: "Отправить",
    ["Forgot password"]: "Забыли пароль",
    ["Password Change"]: "Изменение пароля",
    ["Old Password"]: "Старый пароль",
    ["New Password"]: "Новый пароль",
    ["Repeat New Password"]: "Повторите новый пароль",
    ["Old password is required"]: "Требуется старый пароль",
    ["New password is required"]: "Требуется новый пароль",
    ["Passwords do not match"]: "Пароли не совпадают",
    ["Back to probes"]: "Вернуться к Показателям",
    ["Previous probe"]: "Предыдущий Показатель",
    ["Next probe"]: "Следующий Показатель",
    ["Coefficient"]: "Коэффициент",
    ["Select probes to compare"]: "Выберите Показатель для сравнения",
    ["None"]: "Нет",
    ["Export Graphic"]: "Экспортировать график",
    ["Format"]: "Формат",
    ["Export"]: "Экспорт",
    ["action tabs example"]: "пример вкладок действий",
    ["Delete device"]: "Удалить устройство",
    ["Probe Alert"]: "Предупреждение Показателя",
    ["Device ID"]: "Идентификатор устройства",
    ["Data Type"]: "Тип данных",
    ["Current Temperature"]: "Текущая температура",
    ["Status Updated At"]: "Статус обновлен в",
    ["Limits"]: "Пределы",
    ["Lower Limit"]: "Нижний предел",
    ["Critical Lower Limit"]: "Критический нижний предел",
    ["Add device"]: "Добавить устройство",
    ["Device Name"]: "Название устройства",
    ["Device IP"]: "IP-адрес устройства",
    ["Device Port"]: "Порт устройства",
    ["Company"]: "Компания",
    ["Delete company"]: "Удалить компанию",
    ["Description"]: "Описание",
    ["Add company"]: "Добавить компанию",
    ["Access Tokens"]: "Токены доступа",
    ["Verification"]: "Проверка",
    ["Code"]: "Код",
    ["Confirm"]: "Подтвердить",
    ["Upload image"]: "Загрузить изображение",
    ["Device is active"]: "Устройство активно",
    ["Timezone"]: "Часовой пояс",
    ["Locale"]: "Язык",
    ["For User ID"]: "Идентификатор пользователя",
    ["For User Name"]: "Имя пользователя",
    ["Valid Until"]: "Действительно до",
    ["Add Access Token"]: "Добавить токен доступа",
    ["Create"]: "Создать",
    ["Change password"]: "Изменить пароль",
    ["Connection Updated At"]: "Подключение обновлено",
    ["Connection"]: "Подключение",
    ["Delete user"]: "Удалить пользователя",
    ["Alarms"]: "Предупреждения",
    ["Warnings"]: "Предупреждения",
    ["Warning"]: "Предупреждение",
    ["Errors"]: "Ошибки",
    ["vs"]: "и",
    ["Alarm level"]: "Уровень предупреждения",
    ["Type alarms"]: "Тип предупреждений",
    ["Two-factor authentication"]: "Двухфакторная аутентификация",
    ['IP is not valid']: 'IP недействителен',
    ['Port is not valid']: 'Порт недействителен',
    ['Filtration']: 'Фильтрация',
    ['Time']: "Время",
    ['Graphics']: "График",
    ['Report']: "Отчет",
    ['Phone number']: 'Номер телефона',
    ["User setting"]: "Настройки пользователя",
    ["Allow alarms level"]: "Разрешить уровень тревоги",
    ["Alarm to mail"]: "Оповещение на почту",
    ["Alarm to SMS"]: "Оповещение по SMS",
    ["Two factor auth"]: "Двухфакторная аутентификация",
    ["Decimals"]: "Десятичные",
    ["Limit Decimals"]: "Десятичные лимитов",
    ["Hight Critical Limit Addr"]: "Адрес памяти верхнего критического предела",
    ["Low Critical Limit Addr"]: "Адрес памяти нижнего критического предела",
    ["Hight Limit Addr"]: "Адрес памяти верхнего предела",
    ["Low Limit Addr"]: "Адрес памяти нижнего предела",
    ["Value Addr"]: "Адрес памяти значения",
    ["Baseline Value"]: "Базовое значение",
    ["Is Coil"]: "Является ли Modbus Coils",
    ["Device name is empty"]: "Имя устройства пусто",
    ["Phone number is required"]: "Требуется номер телефона",
    ["Actions"]: "Действия",
    ["Only files with extensions"]: "Разрешены только файлы с расширениями",
    ["Dayly report"]: "Ежедневный отчет",
    ["Weekly report"]: "Еженедельный отчет",
    ["Monthly report"]: "Ежемесячный отчет",
    ["Download"]: "Скачать",
    ["Generate"]: "Создать",
    ["Forbidden"]: "Запрещено",
    ["Device"]: "Устройство",
    ["Confirm delete device"]: "Подтвердите удаление устройства",
    ["Enter the password to confirm the deletion"]: "Введите пароль для подтверждения удаления",
};

export default enDic;
