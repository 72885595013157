import { Box, Button, Divider, Typography } from '@mui/material';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import Switch from '@mui/material/Switch';
import React from 'react';
import userService from '../../../api/user';
import useI18n from '../../../hooks/useI18n';
import useUserInfo from '../../../hooks/useUserInfo';
import BasicModal from '../../custom/Modal/BasicModal';
import PhoneInput from '../../custom/PhoneInput/PhoneInput';

function UserSetting() {
    const {l, language} = useI18n();
    const { user, getCurrentUser } = useUserInfo();
    const [open, setOpen] = React.useState(false);
    const [userSetting, setUserSetting] = React.useState();

    React.useEffect(() => {
        const getData = async () => {
        const currentUser = getCurrentUser();   
         const result = await userService.findById(currentUser.id, language);
         setUserSetting(result);
        }
        getData();
    }, []);

    const handleSubmit = async(event) => {
        event.preventDefault();

        if(userSetting?.phone === '') {
            setOpen(true);
            return;
        }

        if (user?.id) await userService.updateUserAsync({ id: user.id, data: userSetting, lang: language });
      }

      const handleOnChange = (event) => {
        let { name, checked, value } = event.target;

        if(name === 'phoneNumber'){
            setUserSetting({
                ...userSetting,
                'phone': value
            })
            return;
        }
        
        setUserSetting({
          ...userSetting,
          [name]: checked
        })
      }

    return (userSetting ?
        <Box 
        component={'form'}  
        onSubmit={handleSubmit} 
        margin={'0 auto'} 
        padding={2} 
        borderRadius={2} 
        sx={{backgroundColor: 'white'}}>
            {open ?
                <BasicModal isOpened={true}
                    type="warning"
                    title={l('Warning')}
                    text={l('Phone number is required') + '!'}
                    handle={() => setOpen(false)}
                /> : ""
            }
            <Typography component="h2" variant="h5">{l('User setting')}</Typography>
            <Divider />
            <PhoneInput value={userSetting?.phone} name="phoneNumber" sx={{margin: '10px 0'}} handleChange={handleOnChange}/>
            <Box display={'flex'} flexWrap={'wrap'} gap={1}>
                <FormGroup>
                    <FormControlLabel checked={userSetting?.allow_alarms_level0} onChange={handleOnChange} name='allow_alarms_level0' control={<Switch />} label={l("Allow alarms level") + " " + `"${l('Errors')}"`} />
                    <FormControlLabel checked={userSetting?.allow_alarms_level1} onChange={handleOnChange} name='allow_alarms_level1' control={<Switch />} label={l("Allow alarms level") + " " + `"${l('Warning')}"`} />
                    <FormControlLabel checked={userSetting?.allow_alarms_level2} onChange={handleOnChange} name='allow_alarms_level2' control={<Switch />} label={l("Allow alarms level") + " " + `"${l('Messages')}"`} />
                </FormGroup>
                <Divider orientation="vertical" variant="middle" flexItem />
                <FormGroup>
                    <FormControlLabel checked={userSetting?.alarm_to_mail} onChange={handleOnChange} name='alarm_to_mail' control={<Switch />} label={l("Alarm to mail")} />
                    <FormControlLabel checked={userSetting?.alarm_to_sms} onChange={handleOnChange} name='alarm_to_sms' control={<Switch />} label={l("Alarm to SMS")} />
                    <FormControlLabel checked={userSetting?.two_factor_auth} onChange={handleOnChange} name='two_factor_auth' control={<Switch />} label={l('Two factor auth')} />
                </FormGroup>
                <Divider orientation="vertical" variant="middle" flexItem />
                <FormGroup>
                    <FormControlLabel checked={userSetting?.report_send_daily} onChange={handleOnChange} name='report_send_daily' control={<Switch />} label={l("Dayly report")} />
                    <FormControlLabel checked={userSetting?.report_send_weekly} onChange={handleOnChange} name='report_send_weekly' control={<Switch />} label={l("Weekly report")} />
                    <FormControlLabel checked={userSetting?.report_send_monthly} onChange={handleOnChange} name='report_send_monthly' control={<Switch />} label={l('Monthly report')} />
                </FormGroup>
            </Box>
            <Button sx={{mt: 2, display:'flex', flexGrow: 1}} type="submit" size="small" variant="contained" color='primary'>{l('Save')}</Button>
        </Box> : '');
}

export default UserSetting;