import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import {
  Box,
  Button,
  Card,
  CardContent,
  Checkbox,
  Container,
  FormControlLabel,
  IconButton,
  Typography
} from '@mui/material';
import * as React from 'react';
import { useNavigate, useParams } from "react-router-dom";
import roleService from '../../../api/role';
import useI18n from '../../../hooks/useI18n';
import useUserInfo from '../../../hooks/useUserInfo';
import BasicModal from '../../custom/Modal/BasicModal';

export default function Role() {
    const { language, l } = useI18n();
    const { id } = useParams();
    const [role, setRole] = React.useState();
    const [open, setOpen] = React.useState(false);
    const [title, setTitle] = React.useState('');
    const [text, setText] = React.useState('');
    const navigate = useNavigate();
    const {user} = useUserInfo();

    React.useEffect(() => {
        const getRole = async () => {
            try {
                const role = await roleService.getFindByRoleAsync(id, language);
                setRole(role.find((r) => r.user_role === id));
            } catch (error) {
                console.error('Error fetching ROLE:', error);
            }
        }
        getRole();
    },[])

    const handleClose = () => navigate("/roles");

    const handleInputChange = (event) => {
      const { name, checked } = event.target;

      if (checked === true) {
        setRole({
          ...role,
          [name]: true
        })
      } else if (checked === false) {
        setRole({
          ...role,
          [name]: false
        })
      } 
    }
  
    const handleSubmit = async (event) => {
      event.preventDefault();
      try {
        const updateRole = await roleService.updateRoleAsync({ role: role.user_role, data: role, lang: language });
        if (updateRole) {
          setOpen(true);
          setTitle(l('Role saved'));
          setText(l('Saving was successfull. Role saved.'))
        }
      } catch (error) {
          console.error('Error update role:', error);
      }
    }
  
    const deleteRole = async () => {
      try {
        const deleteRole = await roleService.deleteRoleAsync(role.user_role, language);
        
        if (deleteRole) {
          setOpen(true);
          setTitle(l('Operation is successful'));
          setText(l('User was deleted successfully') + ".")
        }
      } catch (error) {
          console.error('Error delete user:', error);
      }
    }
  
    function handleBackButtonClick() {
      navigate('/roles');
    }
  
    return (
      role ? 
      <Container component="main">
        {open ? 
          <BasicModal isOpened={true}
                      type="success"
                      title={title}
                      text={text}
                      handle={handleClose} />
              : ""
        }
        <Card variant="outlined">
          <CardContent sx={{ p: 4}}>
            <Box display="flex" alignItems={'center'} gap={1}>
              <IconButton onClick={handleBackButtonClick}>
                  <ArrowBackIcon />
              </IconButton>
              <Typography component="h1" variant="h5">
                {l("Role: ")}{role?.user_role}
              </Typography>
            </Box>
            <Box sx={{color: '#ccc'}}>{role.comment}</Box>
          <Box
            component="form"
            sx={{ '& .MuiTextField-root': { m: 1, width: '100%' } }}
            noValidate
            autoComplete="off"
            onSubmit={handleSubmit}
          >
            <Box display="flex" justifyContent="space-between">
                <Box display="flex" flexDirection="column">
                    <FormControlLabel control={
                        <Checkbox id="isAllowCreateCompanies"
                                checked={role?.isAllowCreateCompanies}
                                name="isAllowCreateCompanies"
                                onChange={handleInputChange}
                                size="small" />
                    } label={l("Allow create companies")} />
                    <FormControlLabel control={
                        <Checkbox id="isAllowCreateDevice"
                                checked={role?.isAllowCreateDevice}
                                name="isAllowCreateDevice"
                                onChange={handleInputChange}
                                size="small" />
                    } label={l("Allow create devices")} />
                    <FormControlLabel control={
                        <Checkbox id="isAllowCreateRoles"
                                checked={role?.isAllowCreateRoles}
                                name="isAllowCreateRoles"
                                onChange={handleInputChange}
                                size="small" />
                    } label={l("Allow create roles")} />
                    <FormControlLabel control={
                        <Checkbox id="isAllowCreateUsers"
                                checked={role?.isAllowCreateUsers}
                                name="isAllowCreateUsers"
                                onChange={handleInputChange}
                                size="small" />
                    } label={l("Allow create users")} />
                    <FormControlLabel control={
                        <Checkbox id="isAllowCreateAccessTokens"
                                checked={role?.isAllowCreateAccessTokens}
                                name="isAllowCreateAccessTokens"
                                onChange={handleInputChange}
                                size="small" />
                    } label={l("Allow create access tokens")} />
                </Box>
                <Box display="flex" flexDirection="column">
                    <FormControlLabel control={
                        <Checkbox id="isAllowEditCompanies"
                                checked={role?.isAllowEditCompanies}
                                name="isAllowEditCompanies"
                                onChange={handleInputChange}
                                size="small" />
                    } label={l("Allow edit companies")} />
                    <FormControlLabel control={
                        <Checkbox id="isAllowEditDevices"
                                checked={role?.isAllowEditDevices}
                                name="isAllowEditDevices"
                                onChange={handleInputChange}
                                size="small" />
                    } label={l("Allow edit devices")} />
                    <FormControlLabel control={
                        <Checkbox id="isAllowEditRoles"
                                checked={role?.isAllowEditRoles}
                                name="isAllowEditRoles"
                                onChange={handleInputChange}
                                size="small" />
                    } label={l("Allow edit roles")} />
                    <FormControlLabel control={
                        <Checkbox id="isAllowEditUsers"
                                checked={role?.isAllowEditUsers}
                                name="isAllowEditUsers"
                                onChange={handleInputChange}
                                size="small" />
                    } label={l("Allow edit users")} />
                </Box>
                <Box display="flex" flexDirection="column">
                    <FormControlLabel control={
                        <Checkbox id="isAllowDeleteCompanies"
                                checked={role?.isAllowDeleteCompanies}
                                name="isAllowDeleteCompanies"
                                onChange={handleInputChange}
                                size="small" />
                    } label={l("Allow delete companies")} />
                    <FormControlLabel control={
                        <Checkbox id="isAllowDeleteDevices"
                                checked={role?.isAllowDeleteDevices}
                                name="isAllowDeleteDevices"
                                onChange={handleInputChange}
                                size="small" />
                    } label={l("Allow delete devices")} />
                    <FormControlLabel control={
                        <Checkbox id="isAllowDeleteRoles"
                                checked={role?.isAllowDeleteRoles}
                                name="isAllowDeleteRoles"
                                onChange={handleInputChange}
                                size="small" />
                    } label={l("Allow delete roles")} />
                    <FormControlLabel control={
                        <Checkbox id="isAllowDeleteUsers"
                                checked={role?.isAllowDeleteUsers}
                                name="isAllowDeleteUsers"
                                onChange={handleInputChange}
                                size="small" />
                    } label={l("Allow delete users")} />
                    <FormControlLabel control={
                        <Checkbox id="isAllowDeleteAccessTokens"
                                checked={role?.isAllowDeleteAccessTokens}
                                name="isAllowDeleteAccessTokens"
                                onChange={handleInputChange}
                                size="small" />
                    } label={l("Allow delete access tokens")} />
                </Box>
            </Box>
            <Box display="flex" justifyContent="space-between">
                {
                  user?.isAllowEditRoles ?
                    <Button
                      type="submit"
                      color="primary"
                      variant="contained"
                      size='small'
                      sx={{ mt: 3, mb: 2 }}
                    >
                      {l("Save changes")}
                    </Button> 
                    :
                    ""
                }
                {
                  user?.isAllowDeleteRoles ?
                    <Button
                      color="error"
                      variant="outlined"
                      size='small'
                      onClick={deleteRole}
                      sx={{ mt: 3, mb: 2, mr: 2 }}
                    >
                      {l('Delete role')}
                    </Button>
                    :
                    ""
                }
            </Box>
          </Box>
          </CardContent>
        </Card>
      </Container>
      : <></>
    )
  }
