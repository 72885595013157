import { getData, postData } from "../lib/fetchApi";
import { ALARM_URLS } from "../constants/urls";
import { defaultLocale } from "../config";

const alarmService = {
    getReportAlarmsByCompanyIDAsync: ({ companyId, from, to, lang = defaultLocale }) => {
        const query = ((from && to) ? `?from=${from}&to=${to}` : '');
        return getData({ url: ALARM_URLS.FIND_BY_COMPANY_ID + companyId + query, lang })   
    },
    getAlarmsByTimeStampAsync: (lang = defaultLocale) => {  
        return getData({ url: ALARM_URLS.FIND_BY_LAST_TIMESTAMP, lang })   
    }
}
export default alarmService;