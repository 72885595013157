import { Button, FormHelperText, OutlinedInput, FormControl, Checkbox, FormControlLabel, Card, CardContent, CircularProgress } from '@mui/material';
import Box from '@mui/material/Box';
import * as React from 'react';
import { useParams } from 'react-router-dom';
import probeService from '../../../api/probe';
import useI18n from '../../../hooks/useI18n';
import useUserInfo from '../../../hooks/useUserInfo';

export default function Setting() {
    const { language, l } = useI18n();
    const { probeId } = useParams();
    const [title, setTitle] = React.useState('');
    const [text, setText] = React.useState('');
    const [probe, setProbe] = React.useState();
    const [open, setOpen] = React.useState(false);
    const [type, setType] = React.useState('');
    const {user} = useUserInfo();

    React.useEffect(() => {
        const getData = async () => {
            try {
                const result = await probeService.getProbeByIdAsync({id: probeId, lang: language});
                setProbe(result);
            } catch (error) {
                console.error('Error fetching PROBE:', error);
            }
        };
        getData();
    }, []);

    const handleInputChange = (event) => {
        let { name, value, checked } = event.target;

        if (name === 'isCoil') {
            setProbe({
                ...probe,
                [name]: checked
            })
            return;
        }

        setProbe({
            ...probe,
            [name]: value
        })
    }

    const handleSubmit = async (event) => {
        event.preventDefault();
        try {
            const updateProbe = await probeService.updateProbeAsync({ id: probeId, data: probe, lang: language });

            if (updateProbe) {
                setTitle(l('Probe saved'));
                setText(l('Saving was successfull. Probe saved.'));
                setOpen(true);
                setType('success')
            }
        } catch (error) {
            console.error('Error update probe:', error);
        }
    }

    return (probe ? 
        <Box component="main">
            <Card sx={{borderTopRightRadius: 0, borderTopLeftRadius: 0}} variant="outlined">
            <CardContent sx={{ p: 4 }}>
                <Box display="flex" gap={1}>
                    <FormControl sx={{ width: '100%' }} variant="outlined">
                        <FormHelperText id="comment">{l("Comment")}</FormHelperText>
                        <OutlinedInput
                            id="comment"
                            name="comment"
                            defaultValue={probe?.comment}
                            aria-describedby="comment"
                            size="small"
                            onChange={handleInputChange}
                        />
                    </FormControl>
                    <FormControl sx={{width: '100%' }} variant="outlined">
                        <FormHelperText id="decimals">{l("Decimals")}</FormHelperText>
                        <OutlinedInput
                            id="decimals"
                            name="decimals"
                            defaultValue={probe?.decimals}
                            aria-describedby="decimals"
                            size="small"
                            onChange={handleInputChange}
                        />
                    </FormControl>
                </Box>
                <Box display="flex" gap={1}>
                    <FormControl sx={{ width: '100%' }} variant="outlined">
                        <FormHelperText id="limitDecimals">{l("Limit Decimals")}</FormHelperText>
                        <OutlinedInput
                            id="limitDecimals"
                            name="limitDecimals"
                            defaultValue={probe?.limit_decimals}
                            aria-describedby="limitDecimals"
                            size="small"
                            onChange={handleInputChange}
                        />
                    </FormControl>
                    <FormControl sx={{ width: '100%' }} variant="outlined">
                        <FormHelperText id="limitHAddr">{l("Hight Limit Addr")}</FormHelperText>
                        <OutlinedInput
                            id="limitHAddr"
                            name="limitHAddr"
                            defaultValue={probe?.limit_h_addr}
                            aria-describedby="limitHAddr"
                            size="small"
                            onChange={handleInputChange}
                        />
                    </FormControl>
                </Box>
                <Box display="flex" gap={1}>
                    <FormControl sx={{ width: '100%' }} variant="outlined">
                        <FormHelperText id="limitHCritAddr">{l("Hight Critical Limit Addr")}</FormHelperText>
                        <OutlinedInput
                            id="limitHCritAddr"
                            name="limitHCritAddr"
                            defaultValue={probe?.limit_h_crit_addr}
                            aria-describedby="limitHCritAddr"
                            size="small"
                            onChange={handleInputChange}
                        />
                    </FormControl>
                    <FormControl sx={{ width: '100%' }} variant="outlined">
                        <FormHelperText id="limitLAddr">{l("Low Limit Addr")}</FormHelperText>
                        <OutlinedInput
                            id="limitLAddr"
                            name="limitLAddr"
                            defaultValue={probe?.limit_l_addr}
                            aria-describedby="limitLAddr"
                            size="small"
                            onChange={handleInputChange}
                        />
                    </FormControl>
                </Box>
                <Box display="flex" gap={1}>
                    <FormControl sx={{ width: '100%' }} variant="outlined">
                        <FormHelperText id="limitLCritAddr">{l("Low Critical Limit Addr")}</FormHelperText>
                        <OutlinedInput
                            id="limitLCritAddr"
                            name="limitLCritAddr"
                            defaultValue={probe?.limit_l_crit_addr}
                            aria-describedby="limitLCritAddr"
                            size="small"
                            onChange={handleInputChange}
                        />
                    </FormControl>
                    <FormControl sx={{ width: '100%' }} variant="outlined">
                        <FormHelperText id="valueAddr">{l("Value Addr")}</FormHelperText>
                        <OutlinedInput
                            id="valueAddr"
                            name="valueAddr"
                            defaultValue={probe?.value_addr}
                            aria-describedby="valueAddr"
                            size="small"
                            onChange={handleInputChange}
                        />
                    </FormControl>
                </Box>
                <Box display="flex" gap={1}>
                    <FormControl sx={{ width: '100%' }} variant="outlined">
                        <FormHelperText id="valueBaseline">{l("Baseline Value")}</FormHelperText>
                        <OutlinedInput
                            id="valueBaseline"
                            name="valueBaseline"
                            defaultValue={probe?.value_baseline}
                            aria-describedby="valueBaseline"
                            size="small"
                            onChange={handleInputChange}
                        />
                    </FormControl>
                </Box>
                { probe?.is_calculated || probe?.is_recovery|| (!probe.limit_h_addr & !probe.limit_h_crit_addr & !probe.limit_l_addr & !probe.limit_l_crit_addr)?
                <Box display="flex" gap={1}>
                    <FormControl sx={{ width: '100%' }} variant="outlined">
                        <FormHelperText id="limitLCrit">{l("LL")}</FormHelperText>
                        <OutlinedInput
                            id="limitLCrit"
                            name="limitLCrit"
                            defaultValue={probe?.limit_l_crit}
                            aria-describedby="limitLCrit"
                            size="small"
                            onChange={handleInputChange}
                        />
                    </FormControl>
                    <FormControl sx={{ width: '100%' }} variant="outlined">
                        <FormHelperText id="limitL">{l("L")}</FormHelperText>
                        <OutlinedInput
                            id="limitL"
                            name="limitL"
                            defaultValue={probe?.limit_l}
                            aria-describedby="limitL"
                            size="small"
                            onChange={handleInputChange}
                        />
                    </FormControl>
                </Box>
                :
                ""
                }


                { probe?.is_calculated || probe?.is_recovery || (!probe.limit_h_addr & !probe.limit_h_crit_addr & !probe.limit_l_addr & !probe.limit_l_crit_addr)? 
                <Box display="flex" gap={1}>
                    <FormControl sx={{ width: '100%' }} variant="outlined">
                        <FormHelperText id="limitH">{l("H")}</FormHelperText>
                        <OutlinedInput
                            id="limitH"
                            name="limitH"
                            defaultValue={probe?.limit_h}
                            aria-describedby="limitH"
                            size="small"
                            onChange={handleInputChange}
                        />
                    </FormControl>
                    <FormControl sx={{ width: '100%' }} variant="outlined">
                        <FormHelperText id="limitHCrit">{l("HH")}</FormHelperText>
                        <OutlinedInput
                            id="limitHCrit"
                            name="limitHCrit"
                            defaultValue={probe?.limit_h_crit}
                            aria-describedby="limitHCrit"
                            size="small"
                            onChange={handleInputChange}
                        />
                    </FormControl>
                </Box>
                :
                ""
                }


                <Box display="flex" gap={1}>
                    <FormControlLabel control={
                        <Checkbox 
                            id="isCoil"
                            defaultValue={probe?.is_coil || false}
                            name="isCoil"
                            onChange={handleInputChange}
                            size="small" />
                    } label={l("Is Coil")} />
                </Box>
                {
                    user?.isAllowEditDevices ?
                        <Button type='submit'
                            onClick={handleSubmit}
                            color="primary"
                            variant="contained"
                            size='small'
                        >{l('Save changes')}</Button>
                        :
                        ""
                }
            </CardContent>
          </Card>
        </Box>
        :
        <Box component="main">
            <Card sx={{borderTopRightRadius: 0, borderTopLeftRadius: 0, height: '380px', display: 'flex', alignItems: 'center', justifyContent: 'center'}} variant="outlined">
               <CircularProgress />
            </Card>
        </Box>
    );
};