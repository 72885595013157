import en from "./en.js";
import ru from "./ru.js";
import uk from "./uk.js";

const langIndex = {
    en,
    ru,
    uk,
};

export default langIndex;
