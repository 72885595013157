import { Box, Typography } from "@mui/material";
import moment from 'moment';
import React from "react";
import alarmService from "../../../api/alarm";
import userService from "../../../api/user";
import CellType from "../../../constants/type";
import TableData from "../../custom/Table/TableData";

import AlarmsType from "../../../constants/alarms";
import useI18n from "../../../hooks/useI18n";
import useUserInfo from "../../../hooks/useUserInfo";
import DatePickerRange from "../../custom/DatePickerRange/DatePickerRange";

function Alarms({type, companyId}) {
    const [alarms, setAlarms] = React.useState([]);
    const [filterByLevel, setFilterByLevel] = React.useState([]);
    const { l, language } = useI18n();
    const [from, setStartDate] = React.useState('');
    const [to, setEndDate] = React.useState('');
    const {getCurrentUser} = useUserInfo();

    const columns = [
        { id: 'alarm_message', label: l('Messages'), minWidth: 200, type: CellType.STRING },
        { id: 'created_at', label: l('Created At'), minWidth: 200, type: CellType.DATE },
        { id: 'alarm_level', label: l('Alarm level'), minWidth: 200, type: CellType.BOOLALARM },
    ];

    const alarmLevel = [
        {
            alarmLevel: 0,
            title: l('Errors'),
            icon: 0
        },
        {
            alarmLevel: 1,
            title: l('Warning'),
            icon: 1
        },
        {
            alarmLevel: 2,
            title: l('Messages'),
            icon: 2
        },
    ]
    const handleFilterByLevel = (e,value) => {
        const dataFilter = alarmLevel.filter(item => !value.find((v => v.alarmLevel === item.alarmLevel)));
        setFilterByLevel(dataFilter);
        const alarmsData = getAlarms(type);
        setAlarms(alarmsData.filter((alarm) => value.some((filter) => filter.alarmLevel === alarm.alarm_level)));
    }

    const handleStartDateChange = (date) => {
        const startDate = JSON.stringify(date); 
        setStartDate(startDate);
    };
    
    const handleEndDateChange = (date) => {
        const endDate = JSON.stringify(date);
        setEndDate(endDate);
    };

    const getAlarms = (type) => {
        switch (type) {
            case AlarmsType.BY_COMPANY:
                const updateAlarmTimeStamp = async () => {
                    try {
                        await userService.updateAlarmTimeStampAsync(language);
                    } catch (error) {
                        console.error('Error fetching companies:', error);
                    }
                };
                updateAlarmTimeStamp();
        
                const getAlarmsByCompanyId = async () => {
                    try {
                        let startDate = from;
                        let endDate = to;
        
                        if (!startDate) startDate = moment().subtract(7, 'days').format('YYYY-MM-DD HH:mm')
                        if (!endDate) endDate = moment().format('YYYY-MM-DD HH:mm');
                        

                        const user = getCurrentUser();
                        const result = await alarmService.getReportAlarmsByCompanyIDAsync({ companyId: companyId || user?.company_id, from: startDate, to: endDate, lang: language });
                        
                        const formattedAlarms = result.alarms.map(alarm => ({
                            alarm_level: alarm[0],
                            alarm_message: alarm[1],
                            created_at: alarm[2]
                        }));
                        
                        setAlarms(formattedAlarms);
                        return formattedAlarms;
                    } catch (error) {
                        console.error('Error fetching companies:', error);
                    }
                };
                return getAlarmsByCompanyId();
            default:
                break;
        }
    }

    React.useEffect(() => {
        getAlarms(type);
    }, [type, from, to])

    return (
        <Box margin={'10px 0'}>
            <Typography textTransform={"capitalize"} component="h2" variant="h5">{l('Alarms')}</Typography>
            <Box display={'flex'} gap={2} margin={"10px 0px"}>
                <DatePickerRange
                    lableStart={l('Start time')}
                    lableEnd={l('End time')}
                    onChangeStartDate={handleStartDateChange}
                    onChangeEndDate={handleEndDateChange}
                    defaultStartDate={moment().subtract(7, 'days').format('YYYY-MM-DD')}
                    defaultEndDate={moment()}
                />
                {/* <Autocomplete
                    multiple
                    filterOptions={o => filterByLevel}
                    isOptionEqualToValue={(option, value) => option.alarmLevel === value.alarmLevel}
                    limitTags={2}
                    onChange={handleFilterByLevel}
                    id="multiple-limit-tags"
                    options={alarmLevel}
                    getOptionLabel={(option) => option.title}
                    defaultValue={alarmLevel}
                    renderInput={(params) => (
                        <TextField {...params} label={l("Type alarms")} placeholder="Favorites" />
                    )}
                    sx={{ minWidth: '150px' }}
                /> */}
            </Box>
            <TableData columns={columns} rows={alarms} />
        </Box>
    );
}

export default Alarms;