import { TextField } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';
import * as React from 'react';
import { useRecoilState } from "recoil";
import deviceService from '../../../api/device';
import useI18n from '../../../hooks/useI18n';
import { currentCompanyState } from '../../../state/companies';
import BasicModal from '../../custom/Modal/BasicModal';

function isValidIP(ip) {
  // Регулярний вираз для перевірки валідної IP-адреси
  var ipPattern = /^((25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/;
  
  // Перевірка чи відповідає IP адреса шаблону
  return ipPattern.test(ip);
}

function isValidPort(port) {
  // Перевірка, чи введений порт є цілим числом
  if (!/^\d+$/.test(port)) {
    return false;
  }

  // Перевірка, чи порт належить діапазону від 1 до 65535
  const portNumber = parseInt(port, 10);
  return portNumber >= 1 && portNumber <= 65535;
}

export default function AddDevice ({updateDevices}) {
    const { l, language } = useI18n();
    const [open, setOpen] = React.useState(false);
    const [openAlert, setOpenAlert] = React.useState(false);
    const [type] = React.useState('warning');
    const [title] = React.useState(l('Warning'));
    const [text, setText] = React.useState('');
    // const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const [device, setDevice] = React.useState();
    const [selectedCompany] = useRecoilState(currentCompanyState);


    const handleOpen = () => {
      const getCompaniesId = async () => {
        try {
            setDevice({
              ...device,
              company_id: selectedCompany.id
            })
        } catch (error) {
            console.error('Error fetching companies:', error);
        }
      };
      getCompaniesId();
      setOpen(true);
    };

    const handleSubmit = async(event) => {
      event.preventDefault();
      if (!isValidIP(device.ip)) {
        setText(l(l('Device name is empty') + '!'));
        setOpenAlert(true);
        return;
      }

      if (!isValidIP(device.ip)) {
        setText(l(l('IP is not valid') + '!'));
        setOpenAlert(true);
        return;
      }

      if (!isValidPort(device.port)) {
        setText(l('Port is not valid' + '!'));
        setOpenAlert(true);
        return;
      }

      try {
        const createDevice = await deviceService.createDeviceAsync(device, language);
      } catch (error) {
          console.error('Error fetching users:', error);
      }
      updateDevices();
      setOpen(false);
    }

    const handleInputBlur = (event) => {
      let { name, value } = event.target;

      setDevice({
        ...device,
        [name]: value
      })
    }


    function handleCancel () {
      setOpen(false); 
    }

    return (
        <>
          <Button size="small" variant="contained" onClick={handleOpen}>{l('Add device')}</Button>
          <BasicModal isOpened={openAlert}
                type={type}
                title={title}
                text={text}
                handle={() => setOpenAlert(open => !open)}/>
          <Modal
            open={open}
            onClose={handleClose}
          >
            
            <Box 
              component="form"
              sx={{ '& .MuiTextField-root': { m: 1, width: '100%' } }}
              noValidate
              autoComplete="off"
              onSubmit={handleSubmit}
              className="modal">
              <Typography id="modal-modal-title" variant="h6" color='#1976d2' component="h2" sx={{fontSize: 24}}>
                {l('Add device')}
              </Typography>
              <Box display="flex"
                   flexDirection='column'
                   alignItems="center"
                   justifyContent="space-between"
                   mb={2}>
                <TextField 
                          error={device?.comment === ''}
                          required={true}
                          name="comment"
                          id="comment"
                          label={l("Device Name")}
                          onBlur={handleInputBlur}
                          variant="outlined"
                          size="small" />
                <TextField 
                          error={device?.ip === ''}
                          name="ip"
                          required
                          id="ip"
                          label={l("Device IP")}
                          onBlur={handleInputBlur}
                          variant="outlined"
                          size="small" />
                <TextField 
                          error={device?.port === ''}
                          name="port"
                          required
                          id="port"
                          label={l("Device Port")}
                          onBlur={handleInputBlur}
                          variant="outlined"
                          size="small" />
              </Box>
              <Box width={'100%'} display={'flex'}>
                <Button sx={{mr: 3, display:'flex', flexGrow: 1}} type="submit" size="small" variant="contained" color='success'>{l('Save')}</Button>
                <Button sx={{ display:'flex', flexGrow: 1}} onClick={handleCancel} size="small" variant="contained" color='error'>{l('Cancel')}</Button>
              </Box>
            </Box>
          </Modal>
        </>
      );
}