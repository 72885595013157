import * as React from 'react';
import useI18n from '../../../hooks/useI18n';
import companyService from '../../../api/company';
import BasicModal from '../../custom/Modal/BasicModal';
import { useParams, useNavigate } from "react-router-dom";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Container,
         Card,
         CardContent,
         Box,
         IconButton,
         Typography,
         FormControl,
         FormHelperText,
         OutlinedInput,
         FormControlLabel,
         Button,
         InputLabel,
         Select,
         TextField,
         MenuItem,
         Checkbox } from '@mui/material';
import { styled } from '@mui/material/styles';
import InputFileUpload from '../../custom/InputFileUpload/InputFileUpload';
import MoveToInboxIcon from '@mui/icons-material/MoveToInbox';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';

import { languageArray, swappedLanguageArray } from '../../../i18n/selectLang';
import useUserInfo from '../../../hooks/useUserInfo';

const StyledIconButton = styled(IconButton)(({ theme }) => ({
  '&:hover': {
    backgroundColor: 'rgba(255,255,255,0.8)', // Змінюємо колір фону на білий при наведенні
  },
}));

export default function Company() {
    const { language, l } = useI18n();
    const navigate = useNavigate();
    const [company, setCompany] = React.useState();
    const [title, setTitle] = React.useState('');
    const [text, setText] = React.useState('');
    const [type, setType] = React.useState('');
    const [open, setOpen] = React.useState(false);
    const [image, setImage] = React.useState('');
    const [file, setFile] = React.useState(null);
    const { id } = useParams();
    const {user} = useUserInfo();

    React.useEffect(() => {
        const getDataCompany = async () => {
            try {
                const result = await companyService.findCompanyByIdExtendedAsync(id, language);
                setCompany(result); 
            } catch (error) {
                console.error('Error fetching company:', error);
            }
        };
        getDataCompany();
        getImage(id);
    }, []);

    const getImage = async (id) => {
      try {
        const blob = await companyService.getImageByCompanyId(id);
        if (blob.size < 3) {
          setImage('');
          return;
      }
        const imageUrl = URL.createObjectURL(blob);
        setImage(imageUrl || '');
      } catch (error) {
        console.error('getImage error :>> ', error);
      } 
    };

    const handleClose = () => {setOpen(false)};

    const handleSubmit = async (event) => {
        event.preventDefault();
        try {
          const canDeleteImage = !image && !file;
          const updatedCompany = await companyService.updateCompanyAsync({ id: company.id, data: {...company, canDeleteImage}, lang: language, file, type: 'form-data'  });
          await getImage(id);
          if (updatedCompany) {
            setOpen(true);
            setTitle(l('Company saved'));
            setText(l('Saving was successfull. Company saved.'));
            setType('success');
          }
        } catch (error) {
            console.error('Error update company:', error);
        }
      }

    const handleInputChange = (event) => {
        let { name, value, checked, files } = event.target;

        if (name === 'is_active') {
          setCompany({
            ...company,
            [name]: checked
          })
          return;
        }
    
        if (name === "timezone") {
          setCompany({
            ...company,
            [name]: Number(value)
          })
          return;
        }

        if ((name === 'image') || event.dataTransfer) {
          handleDragEmpty(event);

          const allowedTypes = ['image/jpeg', 'image/jpg', 'image/png']; // Масив дозволених MIME-типів

          if (event.dataTransfer) {
              files = event.dataTransfer.files;
              name = "image";
          }

          if (allowedTypes.includes(files[0].type)) {
              setFile(files[0]);
              const reader = new FileReader();
              reader.onload = () => {
                  setImage(reader.result);
              };
              reader.readAsDataURL(files[0]);
              return;
          } else {
              setOpen(open => !open);
              setType('warning');
              setTitle(l(''));
              setText(l('Only files with extensions') + ' .jpeg, .jpg, .png')
          }

         return;
      } 
    
        setCompany({
          ...company,
          [name]: value
        })      
    }

      const deleteCompany = async () => {
        try {
          const deleteCompany = await companyService.deleteCompanyAsync(company.id, language);
          
          if (deleteCompany) {
            setOpen(true);
            setTitle(l('Operation is successful'));
            setText(l('Company was deleted successfully') + ".")
          }
        } catch (error) {
            console.error('Error delete company:', error);
        }
      }

      const handleDragEmpty = (event) => {
        event.preventDefault();
        event.stopPropagation();
      }

    return (
      company ? 
        <Container component="main">
          {open ? 
            <BasicModal isOpened={true}
                        type={type}
                        title={title}
                        text={text}
                        handle={handleClose} />
                : ""
          }
          <Card variant="outlined">
            <CardContent sx={{ p: 4}}>
              <Box display="flex" alignItems={'center'} gap={1}>
                <IconButton onClick={() => {navigate('/companies')}}>
                    <ArrowBackIcon />
                </IconButton>
                <Typography component="h1" variant="h5">
                  {l("Company") + " id:" + company?.id }
                </Typography>
              </Box>
            <Box
              component="form"
              sx={{ '& .MuiTextField-root': { width: '100%' } }}
              noValidate
              autoComplete="off"
              onSubmit={handleSubmit}
              display={'flex'}
              flexDirection={'column'}
              gap={2}
            >
              <Box display="flex" gap={1}>
                <FormControl sx={{ width: '100%' }} variant="outlined">
                  <FormHelperText id="name">{l("Company Name")}</FormHelperText>
                  <OutlinedInput
                    id="company_name"
                    name="company_name"
                    required
                    defaultValue={company?.company_name}
                    aria-describedby="name"
                    size="small"
                    onChange={handleInputChange}
                  />
                </FormControl>
                <FormControl sx={{ width: '100%' }} variant="outlined">
                  <FormHelperText id="description">{l("Company Description")}</FormHelperText>
                  <OutlinedInput
                    id="company_description"
                    name="company_description"
                    defaultValue={company?.company_description}
                    aria-describedby="phone"
                    size="small"
                    onChange={handleInputChange}
                  />
                </FormControl>
              </Box>
              <Box display="flex" gap={1}>
                <TextField name="timezone"
                           id="timezone"
                           type="number"
                           defaultValue={company?.timezone}
                           label={l("Timezone")}
                           onChange={handleInputChange}
                           variant="outlined"
                           size="small"/>
                <FormControl sx={{ width: '100%' }} size="small">
                  <InputLabel>{l('Locale')}</InputLabel>
                  <Select
                        labelId="locale-select-label"
                        id="locale-select"
                        name="locale"
                        defaultValue={company?.locale}
                        label={l('Locale')}
                        onChange={handleInputChange}
                        >
                        {Object.keys(languageArray).map(k =>
                            <MenuItem key={k} value={languageArray[k]}>{languageArray[k].toUpperCase()}</MenuItem>
                        )}
                  </Select>
                </FormControl>
              </Box>
              <Box>
                <FormControlLabel control={
                  <Checkbox disabled={user.user_role === 'super_admin' ? false : true}
                            id="is_active"
                            checked={company?.is_active}
                            name="is_active"
                            onChange={handleInputChange}
                            size="small" />
                } label={l("Company is active")} />
              </Box>
              <Box display={'flex'} alignItems={'center'} flexDirection={'column'} gap={1} sx={{ maxWidth: '180px' }}>
                {image ?
                    <Box>
                        <Box sx={{
                            backgroundImage: `url(${image})`,
                            backgroundSize: 'cover',
                            objectFit: 'cover',
                            width: '180px',
                            height: '180px',
                            border: '1px solid gray',
                            borderRadius: '4px'
                        }}
                            boxSizing={'border-box'}
                            display={'flex'}
                            justifyContent={'end'}
                            height={100}
                        >
                            <StyledIconButton 
                            onClick={() => { 
                                setImage('');
                                setFile(null);
                            }} 
                            sx={{ 
                                backgroundColor: 'rgba(255,255,255,0.6)', 
                                height: '35px', 
                                width: '35px',
                                margin: 0.5 
                                }}>
                                <DeleteForeverIcon color='error' />
                            </StyledIconButton>
                        </Box>
                    </Box>
                    : <Box  className="drop-area"
                            name="image"
                            onDrop={handleInputChange}
                            onDragEnter={handleDragEmpty}
                            onDragOver={handleDragEmpty}>
                            <MoveToInboxIcon />
                      </Box>
                }
                <InputFileUpload sx={{width: '100%'}} name="image" onChange={handleInputChange} accept={".jpeg, .jpg, .png"} />
                </Box>
              <Box display="flex" justifyContent="space-between">
                {
                  user?.isAllowEditCompanies ?
                    <Button
                      type="submit"
                      color="primary"
                      variant="contained"
                      size='small'
                    >
                      {l("Save changes")}
                    </Button>
                    :
                    ""
                }
                {
                  user?.isAllowDeleteCompanies ?
                  <Button
                    color="error"
                    variant="outlined"
                    size='small'
                    onClick={deleteCompany}
                  >
                    {l('Delete company')}
                  </Button>
                  : ""
                }
              </Box>
            </Box>
            </CardContent>
          </Card>
        </Container>
        :
        ''
      )
    }

