import { getData, postData } from "../lib/fetchApi";
import { PROBE_URLS } from "../constants/urls";
import { defaultLocale } from "../config";

const probeService = {
    getProbeByIdAsync: ({ id, lang = defaultLocale }) => {
        return getData({ url: PROBE_URLS.FIND_PROBE_BY_ID + id, lang })
    },
    getReportByDeviceIdAsync: ({ id, from, to, lang = defaultLocale }) => {
        const query = ((from && to) ? `?from=${from}&to=${to}` : '');
        return getData({ url: PROBE_URLS.FIND_BY_DEVICE_EXTENDED + id + query, lang })
    },
    getReportByProbeIdAsync: ({ id, from, to, lang = defaultLocale }) => { 
        const query = ((from && to) ? `?from=${from}&to=${to}` : '');
        return getData({ url: PROBE_URLS.FIND_BY_PROBE_EXTENDED + id + query, lang })
    },
    getReportByProbesIdArrayAsync: ({ ids, from, to, lang = defaultLocale }) => { 
        const data = { probesIdArray: ids, from, to };
        return postData({ url: PROBE_URLS.FIND_BY_PROBE_IDS, lang, data })
    },
    getGenerateReportByDeviceIdAsync: ({ id, from, to, lang = defaultLocale }) => {
        const query = ((from && to) ? `?from=${from}&to=${to}` : '');
        return getData({ url: PROBE_URLS.FIND_BY_DEVICE_GENERATE + id + query, lang })
    },
    getProbeListByDeviceIdAsync: ({ id, average, lang = defaultLocale }) => {
        const query = `?average=${average}`;
        return getData({ url: PROBE_URLS.FIND_BY_DEVICE + id + query, lang })
    },
    getFastProbeListByDeviceId: ({ id, lang = defaultLocale }) => {
        return getData({ url: PROBE_URLS.FAST_FIND_BY_DEVICE + id, lang })
    },
    getProbeTypesAsync: (lang = defaultLocale) => {
        return getData({ url: PROBE_URLS.FIND_PROBE_TYPES, lang })
    },
    createProbeAsync: (data, lang = defaultLocale) => {
        return postData({ url: PROBE_URLS.CREATE, lang, data })
    },
    updateProbeAsync: ({ id, data, lang = defaultLocale }) => {
        return postData({ url: PROBE_URLS.UPDATE + id, lang, data })
    },
    exportProbeAsync: ({ id, data, lang = defaultLocale }) => { 
        return postData({ url: PROBE_URLS.EXPORT_BY_PROBE_ID + id, lang, data })
    },
    // deleteProbeAsync: (data, lang = defaultLocale) => {
    //     return postData({ url: PROBE_URLS.DELETE, lang, data })
    // }
}
export default probeService;