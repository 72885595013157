import ApartmentIcon from '@mui/icons-material/Apartment';
import DevicesIcon from '@mui/icons-material/Devices';
import ErrorIcon from '@mui/icons-material/Error';
import GroupIcon from '@mui/icons-material/Group';
import KeyIcon from '@mui/icons-material/Key';
import PeopleOutlineIcon from '@mui/icons-material/PeopleOutline';
import SmsIcon from '@mui/icons-material/Sms';
import { Badge } from '@mui/material';
import Divider from '@mui/material/Divider';
import MuiDrawer from '@mui/material/Drawer';
import { styled } from '@mui/material/styles';
import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import alarmService from '../../../api/alarm';

import { ExpandLess, ExpandMore, Settings as SettingsIcon } from '@mui/icons-material';
import { Collapse, List, ListItem, ListItemIcon, ListItemText } from '@mui/material';

import useI18n from '../../../hooks/useI18n';
import SlideBarButtonList from './SlideBarButtonList';

import useUserInfo from '../../../hooks/useUserInfo';


const drawerWidth = 230;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.easeOut,
    duration: theme.transitions.duration.standard,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.easeOut,
    duration: theme.transitions.duration.standard,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
});

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme),
    }),
  }),
);



export default function SlideBarMenu(prop) {
  const { open, setOpen, settingsOpen, setSettingsOpen } = prop;
  const navigate = useNavigate()
  const { l, language } = useI18n();
  const [alarmCount, setaAlarmCount] = React.useState(0);
  const [activePage, setActivePage] = React.useState('Devices');
  const {user} = useUserInfo();

  React.useEffect(() => {
    const getAlarmsByCompanyId = async () => {
      try {
  
        const alarms = await alarmService.getAlarmsByTimeStampAsync(language);
        setaAlarmCount(alarms);
      } catch (error) {
        console.error('Error fetching companies:', error);
      }
    };
    getAlarmsByCompanyId();

    if (!open && settingsOpen) {
      setSettingsOpen(false);
    }
  }, [open, settingsOpen, setSettingsOpen]);

  const handleSettingsClick = () => {
    setSettingsOpen(!settingsOpen);
    if (!open) {
      setOpen(true); // Open the sidebar if it's closed
    }
  };


  const slideBarButtonsListTop = [
    {
      text: l('Devices'),
      onClick: () => { navigate('/devices'); setActivePage(l('Devices'))},
      icon: <DevicesIcon />
    },
    {
      text: l('Users'),
      onClick: () => { navigate('/users'); setActivePage(l('Users'))},
      icon: <GroupIcon />
    },
    {
      text: l('Access Tokens'),
      onClick: () => { navigate('/accessTokens'); setActivePage(l('Access Tokens'))},
      icon: <KeyIcon />
    },
    {
      text: l('Companies'),
      onClick: () => { navigate('/companies'); setActivePage(l('Companies'))},
      icon: <ApartmentIcon />,
      role: 'super_admin'
    },
    {
      text: l('Roles'),
      onClick: () => { navigate('/roles'); setActivePage(l('Roles'))},
      icon: <PeopleOutlineIcon />,
      role: 'super_admin'
    }
  ];

  const slideBarButtonsListBottom = [
    {
      text: l('Alarms'),
      onClick: () => { navigate('/alarms'); setActivePage(l('Alarms'))},
      icon: (
        alarmCount ?
          <Badge badgeContent={alarmCount} color="info">
            <ErrorIcon />
          </Badge>
          :
          <ErrorIcon />
      )
    },
  ];

  const settingsMenuItems = [
    {
      text: l('SMS Settings'),
      onClick: () => { navigate('/smsSetting'); setActivePage(l('SMS Settings'))},
      icon: <SmsIcon />
    }
  ];

  return (
    <Drawer variant="permanent" open={open}>
      <DrawerHeader />
      <Divider />
      <SlideBarButtonList user={user} buttons={slideBarButtonsListTop} open={open} activePage={activePage}/>
      {user?.user_role === "super_admin" ?
        <>
          <Divider />
          <ListItem button onClick={handleSettingsClick}>
            <ListItemIcon><SettingsIcon /></ListItemIcon>
            <ListItemText primary={l('Settings')} />
            {settingsOpen ? <ExpandLess /> : <ExpandMore />}
          </ListItem>
          <Collapse in={settingsOpen} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              {settingsMenuItems.map((item, index) => (
                <ListItem button key={index} onClick={item.onClick}>
                  <ListItemIcon className={item.text === activePage ? 'active-button' : ""}>{item.icon}</ListItemIcon>
                  <ListItemText primary={item.text} />
                </ListItem>
              ))}
            </List>
          </Collapse></> : ""}
      <Divider />
      <SlideBarButtonList buttons={slideBarButtonsListBottom} open={open} activePage={activePage}/>
    </Drawer>
  );
}